<template>
  <div class="orderList-view view pa24">
    <p class="fs8 fwbold mb20">成员名字：{{memberName}}</p>
    <div class="flex-a-b-c">
      <div class="w375">
        <div class="bgf5f6 pl20 pt20 pb20 pr20 br10 ">
          <p class="fwbold fs7">跟进概况</p>
          <div class="flex-a-b-c pl40 pr40">
            <div class="d-flex flex-column align-items-center">
              <div class="textc pt20">
                <div class="fs14 cA2 fwbold mb17">{{followData.noFollowCount || 0}}
                  <span class="fs7">人</span>
                </div>
                <div class="fs8 fwbold">
                  <p>
                    今日需跟进数
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content='创建时间在一周之前并且没有成交金额的客户数，并且在最近一周都没有跟进记录的客户数。'
                      placement="top"
                    >
                      <i style="font-size: 16px" class="el-icon-question" />
                    </el-tooltip>
                  </p>
                  <p>（累计7天未跟进）</p>
                </div>
              </div>
            </div>
            <span class="d-block" style="width:1px;background:#D3D3D3;height:175px"></span>
            <div class="d-flex flex-column align-items-center">
              <div class="textc">
                <div class="fs14 cA2 fwbold mb17">{{followData.dealFollowCount || 0}}
                  <span class="fs7">人</span>
                </div>
                <div class="fs8 cA3 fwbold">
                  <p>
                    今日已跟进数
                  </p>
                </div>
              </div>
            </div>
            <span class="d-block" style="width:1px;background:#D3D3D3;height:175px"></span>
            <div class="d-flex flex-column align-items-center">
              <div class="textc">
                <div class="fs14 cA2 fwbold mb17">{{followData.ownCount - followData.dealFollowCount ? followData.ownCount - followData.dealFollowCount :  0 }}
                  <span class="fs7">人</span>
                </div>
                <div class="fs8 fwbold">
                  <p>
                    今日未跟进数
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content='属于独占客户，当天没有跟进并且没有成交金额的客户数。'
                      placement="top"
                    >
                      <i style="font-size: 16px" class="el-icon-question" />
                    </el-tooltip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w800">
        <div class="bgf5f6 pl20 pt20 pb20 pr20 br10">
          <div class="flex-a-b-c">
            <p class="fwbold fs7">数据分析</p>
            <div class="d-flex align-items-center nowrap">
            <el-radio
              style="margin-right: 0;background:#fff"
              v-model="currentTimeDay"
              :label="item.label"
              v-for="(item, index) in timeDay"
              :key="index"
              border
            >
              {{ item.text }}
            </el-radio>
              <el-date-picker
                v-model="pickerValue"
                class="ml10 "
                align="right"
                value-format="yyyy-MM-dd"
                type="daterange"
                @change="selectDate"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </div>
          </div>

          <div class="flex-a-b-c pl30 pr30">
            <div class="d-flex flex-column align-items-center">
              <div class="follow-circle">
                <p class="cA1  textc" style="font-size:20px">{{analysisData.followUserCount || 0}}</p>
              </div>
              <p class="fs8 cA3 fwbold">累计跟客户数</p>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div class="follow-circle">
                <p class="cA1  textc" style="font-size:20px">{{analysisData.followCount || 0}}</p>
              </div>
              <p class="fs8 cA3 fwbold">累计跟进次数</p>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div id="chartView"></div>
              <div class="fs8 cA3 fwbold">
                客户跟进率
                <el-tooltip
                  class="item"
                  effect="dark"
                  content='今日已跟进数 / 今日需跟进数。'
                  placement="top"
                >
                  <i style="font-size: 16px" class="el-icon-question" />
                </el-tooltip>
              </div>
            </div>

          </div>
          
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-end mt30">
      
      <div class="d-flex align-items-center mt10 nowrap">
        <el-input
          v-model="keyword"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入电话、姓名或微信号"
        ></el-input>
        <el-select
          v-model="followType"
          style="width: 150px;margin-left:20px;margin-right:20px"
          placeholder="请选择跟进类型"
        >
          <el-option
            v-for="(item) in stateList"
            :key="item.type"
            :label="item.state"
            :value="item.type"
          />
        </el-select>
        <el-button type="primary" @click="selectOrdersInfoPcAsync">搜索</el-button>
        <el-button @click="resetCondition">重置</el-button>
      </div>
    </div>

    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="cardId" width="100" align="center" label="序号" />
        <el-table-column prop="name" align="center" width="160px" label="姓名" />
        <el-table-column prop="phone" align="center" label="电话" />
        <el-table-column prop="personalWx" align="center" show-overflow-tooltip width="200" label="微信号"/>
        <el-table-column prop="inputDate" width="150px" show-overflow-tooltip label="录入时间" />
        <el-table-column prop="turnoverAmount" align="center" label="成交金额" />
        <el-table-column prop="totalFollowCount" align="center" label="跟进次数" />
        <el-table-column prop="lastFollowTime" width="150px" show-overflow-tooltip  label="最后一次跟进时间" />
        <el-table-column prop="followRemark" align="center" width="350px" show-overflow-tooltip label="跟进内容">
          <template slot-scope="scope">
            <p v-if="scope.row.followRemark.data.type == 7 || scope.row.followRemark.data.type == 8 || scope.row.followRemark.data.type == 9">{{scope.row.followRemark.data.data || ''}}</p>
            <p v-else>{{scope.row.followRemark.data || ''}}</p>
          </template>
        </el-table-column>
        <el-table-column  width="150px" align="center" label="操作">
          <template slot-scope="scope">
            <span class="cblue hover_pointer" @click="toCustomerDetails(scope.row.cardId)">
              详情
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { Chart } from "@antv/g2";
import {
  updateOrderState,
  delOrders,
  updateRefundState,
  exportOrdersInfoPc
} from "@/api/order";
import { 
  analysis,
  customerList
} from "@/api/client";
import { 
  getPcCount
} from "@/api/myStatistics";
import { getDataTimeSec } from "@/utils";
export default {
  props:[''],
  name: "orderList",
  components: {
    commonTable,
  },
  data() {
    return {
      currentTimeDay: 2, 
      orderTypeValue: "", 
      pickerValue: [], //下单时间
      ordersNo:'',//订单编号
      goodsName:'',//商品名称
      phone:'',
      nickeName:'',
      searchCondition:'', //搜索时间
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
      followType:1,
      keyword:"",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      chartData: [{ type: "客户跟进数", value: 0 }],
      timeDay: [
        //时间选项
        { text: "今日", label: 1 },
        { text: "近七天", label: 2 },
        { text: "近三十天", label: 3 },
      ],
      stateList:[
        {
          type:0,
          state:"全部"
        },
        {
          type:1,
          state:"今日需跟进"
        },
        {
          type:2,
          state:"今日已跟进"
        },
        {
          type:3,
          state:"今日未跟进"
        }
      ],
      tableData: [],
      followData:'',
      analysisData:'',
      cardId:'',
      memberName:''
    };
  },
  created() {
    this.memberName = this.$route.query.name
    this.cardId = this.$route.query.id
    this.selectOrdersInfoPcAsync();
    this.getPcCount()
    this.analysis()
  },
  watch:{
    currentTimeDay:{
      handler(val,old){
        console.log(new Date(new Date().toLocaleDateString()).getTime(),'date')
        let start = new Date(new Date().toLocaleDateString()).getTime() + (24*60*60*1000 - 1000)
        this.endTime  = this.getTime(start)  //当天开始时间 00:00:00
        switch(val){
          
          case 1:
            
            
            let currentEndData = new Date(new Date().toLocaleDateString()).getTime() + 1
            this.startTime= this.getTime(currentEndData)  //当天结束时间23:59:59
            break;
          case 2:
            let sevenDays = new Date(new Date().toLocaleDateString()).getTime() -7*3600*24*1000;
            this.startTime = this.getTime(sevenDays)  //当天结束时间23:59:59
            break;
          case 3:
            let thirtyDays = new Date(new Date().toLocaleDateString()).getTime()-30*3600*24*1000;
            this.startTime = this.getTime(thirtyDays)  //当天结束时间23:59:59
            break;
        }
        this.analysis()
      },
      immediate:true
    }
  },
  methods: {
    getPcCount(){
      let data  = {
        type: 1,
        cardId:this.cardId
      }
      getPcCount(data)
      .then(res=>{
        console.log(res)
        this.followData = res.data
      })
    },
    resetCondition(){
      this.followType = ''
      this.keyword = '' 
    },
    selectDate(){
      this.currentTimeDay = 0
      this.startTime = this.pickerValue && `${this.pickerValue[0]} 00:00:00`
      this.endTime = this.pickerValue && `${this.pickerValue[1]} 23:59:59`
    },
    analysis(){
      let data  = {
        type:1,
        cardId:this.cardId,
        startTime:this.startTime,
        endTime:this.endTime
      }
      analysis(data)
      .then(res=>{
        if(res.data){
            this.analysisData = res.data
            this.chartData[0].value =  res.data.followRate || 0
            
        }
        if (this.chartModule) {
          this.chartModule.render();
        } else {
          this.initComponent()
        }
        
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //导出
    exportOrder(){
      let data = {
        orderState:''
      }
      exportOrdersInfoPc(data).then(res=>{
        let blob = new Blob(["\ufeff",res],{ type: 'text/csv,charset=UTF-8'});

        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          window.navigator.msSaveOrOpenBlob(blob, 'fileName.csv');
        } else {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'fileName.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        // const fileName = '订单列表.xls'
        // if ('download' in document.createElement('a')) { // 非IE下载
        //   const elink = document.createElement('a')
        //   elink.download = fileName
        //   elink.style.display = 'none'
        //   elink.href = URL.createObjectURL(blob)
        //   document.body.appendChild(elink)
        //   elink.click()
        //   URL.revokeObjectURL(elink.href) // 释放URL 对象
        //   document.body.removeChild(elink)
        // } else { // IE10+下载
        //   navigator.msSaveBlob(blob, fileName)
        // }

        let link = document.createElement('a');
        let url = window.URL.createObjectURL(res);
        link.href = url;
        link.download = '订单列表.xls';

        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(err=>{
        console.log(err)
      })
    },
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 8, 9].includes(columnIndex)) {
        const _row = this.spanArr[columnIndex][rowIndex];
        const _col = _row > 0 ? 1 : 0;
        // console.log('第'+rowIndex+'行','第'+i+'列','rowspan:'+_row,'colspan:'+_col)
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    /**@method 获取订单列表 */
    async selectOrdersInfoPcAsync() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize:this.pageSize,
        type:1,
        keywords:this.keyword,
        filterType:this.followType,
        cardId:this.cardId
      };
      try{
        const result = await customerList(data);
        this.loading = false
        if(result.data){
          this.tableData = result.data.pageInfo.list;
          this.total = result.data.pageInfo.total
          this.tableData.forEach(item=>{
              item.followRemark = JSON.parse(item.followRemark)
              if(this.checkJsonString(item.followRemark.data) && item.followRemark.type){
                item.followRemark.data = JSON.parse(item.followRemark.data)
              }
              if(!item.followRemark.type){
                item.followRemark.type = 3
              }
          })
          console.log(this.tableData)
          
        }else{
          this.tableData = []
        }
      }
      catch(err){
        this.loading = false
      }
    },
    //判断是否为json字符
    checkJsonString(str){
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          console.log('转换成功：'+obj);
          return true;
        } catch(e) {
          console.log('error：'+str+'!!!'+e);
          return false;
        }
      }
    },
    initComponent() {
      let than = this
      than.chartModule = new Chart({
        container: "chartView",
        autoFit: true,
        height: 135,
        width: 135,
      });
      than.chartModule.data(this.chartData);
      than.chartModule.legend(false);
      than.chartModule.tooltip({
        showMarkers: false,
        position:'bottom'
      });
      than.chartModule.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color = "rgba(81, 203, 205, 1)";
          data.push({ type: "未跟进", value: 100 - data[0].value });
          view.data(data);
          view.coordinate("theta", {
            radius: 1,
            innerRadius: 0.8,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#eceef1"])
            .style({
              opacity: 1,
            })
            

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value + '%',
            style: {
              fontSize: 18,
              fill: "#000",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 0,
          });

          view.interaction("element-active");
        },
      });
      than.chartModule.render();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    //客户详情
    toCustomerDetails(id){
      this.$router.push('../../customerDetall?id=' + id)
    },
    getTime(nS) { 
      var date = new Date(nS); //获取一个时间对象
      let y=  date.getFullYear(); // 获取完整的年份(4位,1970)
      let m = date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1  ; // 获取月份(0-11,0代表1月,用的时候记得加上1)
      let d= date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate() ; // 获取日(1-31)
      let t = date.getTime() <10 ? '0'+(date.getTime()) : date.getTime() ; // 获取时间(从1970.1.1开始的毫秒数)
      let h = date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()  ; // 获取小时数(0-23)
      let  mm =  date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes() ; // 获取分钟数(0-59)
      let s = date.getSeconds() < 10 ? '0'+( date.getSeconds()): date.getSeconds()  ; // 获取秒数(0-59)
        return y+'-'+m+'-'+d+' '+h+':'+mm+':'+s;
    },
  },
};
</script>

<style scoped lang='scss'>
.orderList-view {
  padding-top: 14px;
}
.follow-circle{
  margin-top: 23px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 10px solid #51CBCD;
  display: flex;
  justify-content:center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 25px;
}
</style>
